@use '@/styles/typography.module';
@use '@/styles/button.module';
@import '@/styles/breakpoints.module';

.stepsBlockWrapper {
  overflow-x: auto;
}

.stepsBlock {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  @include tablet {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
  }
  @include desktop {
    gap: 8px;
  }
}

.stepCard {
  border-radius: var(--border-radius-step-card);
  background-color: var(--color-card-bg);
  padding: 16px;

  @include tablet {
    min-width: unset;
    max-width: 360px;
  }
  @include desktop {
    padding: 24px;
  }

  .topRow {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    @include desktop {
      margin-bottom: 24px;
    }
  }

  .square {
    background-color: var(--color-step-square-bg);
    border-radius: var(--border-radius-step-square);
    padding: 4px;
    width: 32px;
    height: 32px;
    line-height: 26px;
    text-align: center;
    color: var(--palette-white);
    margin-inline-end: 12px;
    @include typography.h6Mobile_;
  }

  a {
    @include button.btnLink_;
    text-decoration: underline;
  }
}
