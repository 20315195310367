@import '@/styles/breakpoints.module';

.card {
  width: 100%;
  height: 100%;
  background-color: var(--palette-white);
  display: flex;
  flex-direction: column;

  border-radius: 12px;

  gap: 12px;
  padding: 16px;
  box-shadow: var(--shadow-base);
  @include tablet {
    gap: 20px;
    padding: 24px;
  }
}

.title {
  text-align: center;
  margin-bottom: auto;
  min-height: 2lh;
}

.countdown {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 4px;
}

.countdownEndsIn {
  color: var(--palette-gray-900) !important;
}

.countdownContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.countdownTime {
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countdownTimeValue {
  color: var(--palette-primary-700) !important;

  height: 28px;
  @include tablet {
    height: 36px;
  }
}

.separator {
  margin-bottom: 16px;
}

.countdownTimeLabel {
  color: var(--palette-gray-500) !important;
  height: 16px;
}

.contestStats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.contestStat {
  display: flex;
  flex-direction: column;
  gap: 4px;

  background-color: var(--palette-gray-70);
  border-radius: 8px;
  padding: 12px 16px;

  .contestStatValue {
    font-weight: var(--palette-font-weight-medium);
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.contestStat:nth-child(1) {
  svg {
    color: var(--palette-orange);
  }
}

.contestStat:nth-child(2) {
  svg {
    color: var(--palette-primary-700);
  }
}

.contestStatLabel {
  color: var(--palette-gray-500);
}

.transparentText {
  color: var(--palette-gray-400);
}

.ctas {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}
