@import '@/styles/breakpoints.module';

.cardsGrid {
  display: grid;
  justify-items: center;
  gap: 16px;
  width: 100%;

  & > * {
    max-width: 360px;
  }

  @include tablet {
    grid-template-columns: repeat(2, 316px);
    justify-content: center;
  }

  @include desktop {
    display: flex;

    & > * {
      flex: 1 1 360px;
    }
  }
}

.slide.slide {
  width: 100%;
  pointer-events: auto;

  @include desktop {
    width: 360px;
  }
}

.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 376px;

  @include tablet {
    height: 452px;
  }
}
