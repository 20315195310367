@import '@/styles/breakpoints.module';

.brandCard {
  h3,
  p {
    color: var(--palette-white);
  }
}

.brandCardImage {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;

  img {
    width: 70%;
    height: auto;
    background: rgba(255, 255, 255, 0.07);
    backdrop-filter: blur(5px);
  }

  @include tablet {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  @include desktop {
    img {
      width: 377px;
    }
  }
}

.contactUs {
  text-align: center;
  margin-top: 16px;
}

.tcs {
  text-align: center;
  display: block;
  margin-top: 16px;
}
