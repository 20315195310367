@import '@/styles/breakpoints.module';

.cardImage {
  width: 100%;

  margin-top: 8px;

  @include tablet {
    margin-top: 16px;
  }
  @include desktop {
    margin-top: 32px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
