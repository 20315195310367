@import '@/styles/breakpoints.module';
@import '@/blocks/components/Container/styles.module';
@import '@/styles/typography.module';

section.section {
  margin-top: 40px;
  margin-bottom: 40px;
  @include tablet {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  @include desktop {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

.sectionsGroup {
  margin: 0 8px;
  padding: 40px 0;
  border-radius: var(--border-radius-cta-block-container);

  @include tablet {
    margin: 0 20px;
    padding: 80px 0;
  }

  @include desktop {
    margin: 0 30px; // TODO clear this
    padding: 120px 0;
  }

  // TODO another solution
  & *[class*='_container_'] {
    padding: 0 12px;
    @include tablet {
      padding: 0 40px;
    }
  }
}

.textBlock {
  max-width: 750px;
  margin: 0 auto;
  text-align: center;

  h2 {
    margin-bottom: 16px;
    @include tablet {
      margin-bottom: 24px;
    }
  }
}

.tableBlock {
  display: flex;
  width: 100%;
  overflow-x: hidden;
  padding-top: 56px;
  transition-duration: 300ms;
  scroll-behavior: smooth;
  @include tablet {
    padding-top: 72px;
  }

  .columnBlock {
    border-radius: var(--palette-border-radius-m);
    width: 25%;

    .contentRow {
      padding: 8px;
      color: var(--color-subtitle);
      @include bodyS_;
      @include tablet {
        padding: 12px;
        @include bodyM_;
      }
      @include desktop {
        padding: 12px 24px;
      }

    }


  }


  .tableItem {
    max-width: 26.5%;
    width: 100%;
  }
}

table.table {
  width: 100%;

  .topRow {
    th:nth-of-type(1) {
      display: none;
      @include tablet {
        display: block;
      }
    }

    th.topPartCard {
      position: relative;
      box-sizing: border-box;
      color: var(--palette-white) !important;
      z-index: 0;

      &:before {
        content: '';
        height: 100%;
        display: block;
        position: absolute;
        z-index: -1;
        bottom: 0;
        inset-inline-start: 0;
        width: 100%;
        background-color: var(--palette-gray-900);
        border-start-start-radius: 12px;
        border-start-end-radius: 12px;
      }

      span {
        display: block;
        position: absolute;
        top: -19px;
        inset-inline-end: 12px;
        background-color: var(--palette-primary-100);
        color: var(--palette-primary-700);
        border-radius: 24px;
        padding: 6px 14px;
        @include bodyXS_;
        rotate: 4deg;
      }
    }

    &Cell {
      padding: 24px 24px 12px 24px;
      text-align: start;
      @include tablet {
        padding: 16px 12px;
      }
      @include desktop {
        padding: 24px;
      }
    }

  }

  .bottomRow {
    th:nth-of-type(1) {
      display: none;
      @include tablet {
        display: block;
      }
    }

    &Cell {
      padding: 10px 8px 8px 8px;
      @include tablet {
        padding: 16px 12px;
      }
      @include desktop {
        padding: 24px;
      }

      .specBtn {
        width: 100%;
      }
    }

    th.bottomPartCard {
      position: relative;
      box-sizing: border-box;
      z-index: 0;

      &:before {
        content: '';
        height: 100%;
        display: block;
        position: absolute;
        z-index: -1;
        top: 0;
        inset-inline-start: 0;
        width: 100%;
        background-color: var(--palette-gray-900);
        border-end-start-radius: 12px;
        border-end-end-radius: 12px;
      }

      a.specBtn {
        background-color: var(--color-accent-heading-fragment);
        color: var(--palette-white);

        &:hover {
          background: none;
          color: var(--color-accent-heading-fragment);
        }
      }
    }
  }


  tr:nth-of-type(even) {

    td:nth-of-type(2) {
      background-color: var(--palette-gray-900);
      color: var(--palette-white) !important;

      span {
        color: var(--palette-white) !important;
      }
    }
  }

  tr:nth-of-type(odd) {

    td:nth-of-type(2) {
      background-color: var(--palette-gray-700);
      color: var(--palette-white) !important;

      span {
        color: var(--palette-white) !important;
      }
    }
  }

  tbody.tableContentBlock {
    tr:nth-of-type(odd) {
      background-color: var(--palette-white);
    }

    th.rowTitle {
      display: none;
      color: var(--palette-gray-900);
      font-weight: var(--palette-font-weight-medium);
      text-align: start;

      @include bodyXS_;
      @include tablet {
        display: flex;
        align-items: center;
        @include bodyS_;
      }
      padding: 8px;
      //@include bodyS_;
      @include tablet {
        padding: 12px;
        //@include bodyM_;
      }
      @include desktop {
        padding: 12px 24px;
      }

    }

    td {
      min-width: 75vw;

      padding: 8px;
      color: var(--color-subtitle);

      @include bodyS_;
      @include tablet {
        padding: 12px;
        @include bodyM_;
        min-width: unset;
      }
      @include desktop {
        padding: 12px 24px;
        max-width: 335px;
      }

      span {
        display: block;
        margin-bottom: 4px;
        color: var(--palette-gray-900);
        @include bodyXS_;
        font-weight: var(--palette-font-weight-medium);
        @include tablet {
          display: none;
        }
      }
    }
  }
}

.relativeBlock {
  position: relative;
}

.customArrow {
  width: 44px;
  min-width: 44px;
  height: 44px;
  position: absolute;
  opacity: .6;

  &Prev {
    bottom: 296px;
    inset-inline-start: 8px;
  }

  &Next {
    bottom: 296px;
    inset-inline-end: 8px;

    img {
      rotate: 180deg;
    }
  }

  @include tablet {
    display: none !important;
  }
}


