@import '@/styles/breakpoints.module';

.cardBody {
  height: 100%;
  display: flex;
  padding: 16px;
  gap: 16px;

  flex-direction: column;

  border-radius: var(--palette-border-radius-m);

  background-color: var(--palette-white);

  @include tablet {
    padding: 32px;
  }
}

.cardWithSeparator {
  gap: 0;
}
