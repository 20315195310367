@import '@/styles/breakpoints.module';

.cardHeading {
  display: flex;
  align-items: center;
  gap: 12px;
}

.title {
  color: var(--palette-black) !important;
}

.subheading.subheading.subheading {
  margin-top: 8px;
  color: var(--palette-gray-600);
}

.separator {
  margin: 12px 0;

  @include tablet {
    margin: 16px 0;
  }
}
